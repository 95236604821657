import React from 'react'
import PropTypes from 'prop-types'
import BackgroundImage from 'gatsby-background-image'


const PreviewCompatibleBackgroundImage = ({ imageInfo, className, backgroundColor, tag, children }) => {
  const imageStyle = { borderRadius: '5px' }
  const { alt = '', childImageSharp, image } = imageInfo

  if (!!image && !!image.childImageSharp) {
    return (
      <BackgroundImage
        Tag={tag}
        className={className}
        fluid={image.childImageSharp.fluid}
        backgroundColor={`#040e18`}
      >
        {children}
      </BackgroundImage>
    )
  }

  if (!!childImageSharp) {
    return (<BackgroundImage
      Tag={tag}
      className={className}
      fluid={childImageSharp.fluid}
      backgroundColor={`#040e18`}
    >
      {children}
    </BackgroundImage>)
  }

  if (!!image && typeof image === 'string')
    return (
      <section
        className={className}
        style={{
          background: `url(${image})`,
          backgroundColor: {backgroundColor},
          backgroundSize: "cover"
        }}
      >
        {children}
      </section>
    )

  return null
}

export default PreviewCompatibleBackgroundImage
