import React from 'react'
import { graphql } from 'gatsby'
import Layout from "../components/Layout";
import {IndexPageTemplate} from "../components/IndexPage";

const IndexPage = ({ data, pageContext }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <IndexPageTemplate
        heading={frontmatter.heading}
        subheading={frontmatter.subheading}
        tiles={frontmatter.tiles}
        staffButtonText={frontmatter.staffButtonText}
        staffButtonLink={frontmatter.staffButtonLink}
        staffHeader1={frontmatter.staffHeader1}
        staffHeader2={frontmatter.staffHeader2}
        staff={frontmatter.staff}
        imageAboutUs={frontmatter.imageAboutUs}
        aboutUsHeader={frontmatter.aboutUsHeader}
        aboutUsSubheader1={frontmatter.aboutUsSubheader1}
        aboutUsSubheader2={frontmatter.aboutUsSubheader2}
        aboutUsText={frontmatter.aboutUsText}
        aboutUsBullets={frontmatter.aboutUsBullets}
        aboutUsButtonText={frontmatter.aboutUsButtonText}
        aboutUsButtonLink={frontmatter.aboutUsButtonLink}
        aboutUsBg={data.aboutUsBg}
        siteDir={pageContext.siteDir}
      />
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPageTemplate($id: String) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        heading
        subheading
        tiles {
          icon
          heading
          description
        }
        staffButtonText
        staffButtonLink
        staffHeader1
        staffHeader2
        staff {
          image {
            childImageSharp {
              fluid(maxWidth: 300, maxHeight: 300) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          fullName
          description
        }
        imageAboutUs {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        aboutUsHeader
        aboutUsSubheader1
        aboutUsSubheader2
        aboutUsText
        aboutUsBullets {
          bullet
        }
        aboutUsButtonText
        aboutUsButtonLink
      }
    }
    aboutUsBg: file(relativePath: { eq: "banner-bg.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
